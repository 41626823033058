<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <div v-if="$route.meta.keepAlive">
      <van-tabbar v-model="$store.state.tabBar"
                  active-color="#07c160"
                  @change="tabbarChange"
                  style="border-top:1px solid #f5f5f5">
        <van-tabbar-item name="index"
                         icon="wap-home">主页</van-tabbar-item>
        <!-- <van-tabbar-item name="order"
                         icon="balance-list">申请</van-tabbar-item> -->
        <van-tabbar-item name="user"
                         icon="manager">用户</van-tabbar-item>
      </van-tabbar>
    </div>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { getWxUser } from '@/api/public'

export default {
  name: 'app',
  data () {
    return {};
  },
  methods: {
    tabbarChange (name) {
      this.$router.push('/' + name);
      this.$store.state.tabBar = name;
    }
  },
  created () {
    // wp
    // localStorage.setItem('auth', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJiYW5rSWQiOiI4MjBhZTUxMi03ZjllLTRhOWEtYjlkYy1mZTcxMWFjNmRhNTciLCJJZCI6ImNhNGQ4ZmRhLTk4MzMtNDBlMC1iZjU5LWM0NzU4OWRjMmRjNCIsIkV4cGlyeURhdGVUaW1lIjoiMjAyMC0wNy0wNlQxMzoxODoxOS44OTk1NDQ2KzA4OjAwIn0.ljhRH_aSwPjl2lKNpAhZrJpHdnCuvbx8uwgyPz12L6g')

    //localStorage.setItem('auth', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJiYW5rSWQiOiI2NjU3NmQyYy0xNGVjLTRhNmYtYTY4OC03OGVkMTFjMDg0MjQiLCJJZCI6ImMxZDVhMWZjLWU1ODgtNDAzZS1hYzE4LTNiYzMwMzYzODRiZSIsIkV4cGlyeURhdGVUaW1lIjoiMjAyMS0wMi0xMFQyMTowODo0NS40MzkyOTE1KzA4OjAwIn0.pWM-IOlSzn1SjXpuoUCk-peNeXEoooON0hsQEQyjnhY')

    //localStorage.setItem('auth', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJiYW5rSWQiOiIwYTMxNWU1OC05NDk3LTRkM2YtOTIxYS05ZmM1N2ZjMDg2MTAiLCJJZCI6ImM1YjYzMmQzYmYyNDQ4YzI5MmRkNWViOGJlMjBmNTZmIiwiRXhwaXJ5RGF0ZVRpbWUiOiIyMDIxLTA2LTAzVDA5OjM5OjE0LjcwODEzNTcrMDg6MDAifQ.E_b64J1uORd8q3QAS2dhQPIxEP6TrRoE4aefnkmxd8Y')
    let searchURL = window.location.search;
    let code = '';
    let state = '';
    let router = '';
    let openId = '';
    let nickname = '';
    let heaimg = '';

    if (searchURL) {
      searchURL = searchURL.substring(1, searchURL.length);
      //alert(searchURL);
      code = searchURL.split("&")[0].split("=")[1];
      //alert(code);
      if (code == "authother") {
        openId = searchURL.split("&")[1].split("=")[1];
        nickname = searchURL.split("&")[2].split("=")[1];
        heaimg = searchURL.split("&")[3].split("=")[1];
        state = searchURL.split("&")[4].split("=")[1];
        router = searchURL.split("&")[5].split("=")[1];
        //alert(router);
      } else {
        let stateArr = searchURL.split("&")[1].split("=")[1];
        // state = stateArr.split("%7C")[0];
        // router = stateArr.split("%7C")[1]; 通用
        //长汀
        state = stateArr.split("|")[0];
        router = stateArr.split("|")[1];
      }
    }

    let arg = {
      code: code,
      openId: openId,
      nickname: nickname,
      heaimg: heaimg,
      appKey: state,
    }

    if (localStorage.getItem('auth')) {
      arg.auth = localStorage.getItem('auth');
    }

    getWxUser(arg).then((res) => {
      localStorage.setItem('auth', res.data);
      if (router) {
        this.$router.push('/' + router);
      }
    }).catch(e => {
      console.log(e);
    })
  }
}
</script>
<style lang="less">
.displayflex {
  display: flex;
}

.flexli {
  flex: 1;
}

.van-doc-demo-block__title {
  margin: 0;
  padding: 8px 8px 0px;
  color: rgba(69, 90, 100, 0.6);
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
}

.van-nav-bar {
  border-bottom: 1px solid #f5f5f5;
}
</style>
