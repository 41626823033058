import axios from "axios";
import {
  Toast
} from 'vant';

let baseURL = ' http://193.112.195.55:8868/'
const instance = axios.create({
  baseURL: baseURL,
  timeout: 3000
});

instance.interceptors.response.use(
  response => {
    // 定时刷新access-token
    return response;
  },
  error => {
    //return Promise.reject(error);
    return Promise.reject({
      msg: error.response.data.msg ?
        error.response.data.msg : error.response.data.message,
      res: error.response
    });
  }
);


function baseRequest(options) {
  const headers = options.headers || {
    'Content-Type': 'application/json',
    'auth': localStorage.getItem('auth')
  };
  options.headers = headers;
  return instance(options).then(res => {
    const data = res.data || {};
    if (res.status == 401) {
      Toast('登陆过期');
      localStorage.removeItem('auth')
      // wp
      //window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa182ae56445daf73&redirect_uri=http://wphouse.hscarstop.cn/fangjiawp&response_type=code&scope=snsapi_userinfo&state=820ae512-7f9e-4a9a-b9dc-fe711ac6da57#wechat_redirect";

      // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxaa4c7eeef8ac2222&redirect_uri=http://cdhouse.hscarstop.cn/fangjiawp&response_type=code&scope=snsapi_userinfo&state=66576d2c-14ec-4a6f-a688-78ed11c08424#wechat_redirect";
      // window.location.href = "http://zpmp.hscarstop.cn/zpzxsd/oauth/house";
      window.location.href = "http://ctns.wxywsb.hscarstop.cn?type=house";
      //大田演示
      //window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx405341c153fa076c&redirect_uri=http://dthouse.hscarstop.cn/fangjiadt&response_type=code&scope=snsapi_userinfo&state=b77bb2c104f247058aea1c82f1c5c08a#wechat_redirect";


      ////window.location.href = "http://shns.wxweb.hscarstop.cn?type=house";
    }
    if (res.status !== 200) {
      Toast('请求失败');
      return Promise.reject({
        msg: "请求失败",
        res,
        data
      });
    }

    if (data.code == 1) {
      return Promise.resolve(data, res);
    } else {
      Toast(data.msg);
      return Promise.reject({
        msg: res.data.msg,
        res,
        data
      });
    }
  }).catch(e => {
    if (e.res.status == 401) {
      Toast('登陆过期');
      localStorage.removeItem('auth');
      // window.location.href = "http://shns.wxweb.hscarstop.cn?type=house";
      window.location.href = "http://ctns.wxywsb.hscarstop.cn?type=house";
      //window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx280da0af42f50ce2&redirect_uri=http://zphouse.hscarstop.cn/fangjiazp&response_type=code&scope=snsapi_userinfo&state=0a315e58-9497-4d3f-921a-9fc57fc08610#wechat_redirect";
      // wp
      //window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa182ae56445daf73&redirect_uri=http://wphouse.hscarstop.cn/fangjiawp&response_type=code&scope=snsapi_userinfo&state=820ae512-7f9e-4a9a-b9dc-fe711ac6da57#wechat_redirect";

      // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxaa4c7eeef8ac2222&redirect_uri=http://cdhouse.hscarstop.cn/fangjiawp&response_type=code&scope=snsapi_userinfo&state=66576d2c-14ec-4a6f-a688-78ed11c08424#wechat_redirect";

      //大田演示
      //window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx405341c153fa076c&redirect_uri=http://dthouse.hscarstop.cn/fangjiadt&response_type=code&scope=snsapi_userinfo&state=b77bb2c104f247058aea1c82f1c5c08a#wechat_redirect";
    }
    if (e.res.status !== 200) {
      Toast('请求失败');
      return Promise.reject(e);
    }
  });
}

/**
 * http 请求基础类
 * 参考文档 https://www.kancloud.cn/yunye/axios/234845
 *
 */
const request = ["post", "put", "patch"].reduce((request, method) => {
  /**
   *
   * @param url string 接口地址
   * @param data object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = (url, data = {}, options = {}) => {
    return baseRequest(
      Object.assign({
        url,
        data,
        method
      }, options)
    );
  };
  return request;
}, {});

["get", "delete", "head"].forEach(method => {
  /**
   *
   * @param url string 接口地址
   * @param params object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = (url, params = {}, options = {}) => {
    return baseRequest(
      Object.assign({
        url,
        params,
        method
      }, options)
    );
  };
});

export default request;