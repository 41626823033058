import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/index',
    meta: {
      keepAlive: true
    },
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      keepAlive: true
    },
    component: () => import('../views/Index.vue')
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      keepAlive: true
    },
    component: () => import('../views/order.vue')
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      keepAlive: true
    },
    component: () => import('../views/user.vue')
  },
  {
    path: '/fangjia',
    name: 'fangjia',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/fangjia.vue')
  },
  {
    path: '/fangjiawp',
    name: 'fangjiawp',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/fangjiawp.vue')
  },
  {
    path: '/fangjiact',
    name: 'fangjiact',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/fangjiact.vue')
  },
  {
    path: '/fangjiadt',
    name: 'fangjiadt',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/fangjiadt.vue')
  },
  {
    path: '/fangjiazp',
    name: 'fangjiazp',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/fangjiazp.vue')
  },
  {
    path: '/jisuan',
    name: 'jisuan',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/jisuan.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/detail.vue')
  },
  {
    path: '/fangdai',
    name: 'fangdai',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/fangdai.vue')
  },
  {
    path: '/fangdaiwp',
    name: 'fangdaiwp',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/fangdaiwp.vue')
  },
  {
    path: '/fangdaizp',
    name: 'fangdaizp',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/fangdaizp.vue')
  },
  {
    path: '/fangdaidt',
    name: 'fangdaidt',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/fangdaidt.vue')
  },
  {
    path: '/result',
    name: 'result',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/result.vue')
  },
  {
    path: '/imgList',
    name: 'imgList',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/imgList.vue')
  },
  {
    path: '/querylist',
    name: 'querylist',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/querylist.vue')
  },
  {
    path: '/dot',
    name: 'dot',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/dot.vue')
  },
  {
    path: '/menu',
    name: 'menu',
    meta: {
      keepAlive: false
    },
    component: () => import('../views/menu.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router