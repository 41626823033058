import request from "../utils/request";

export function getWechatConfig() {
  return request.get(
    "/wechat/config", {
      url: document.location.href
    }, {
      login: false
    }
  );
}

export function wechatAuth(code, spread, login_type) {
  return request.get(
    "/wechat/auth", {
      code,
      spread,
      login_type
    }, {
      login: false
    }
  );
}

export function getStreet() {
  return request.get("/api/base/getSelect");
}
export function getTown() {
  return request.get("/api/base/gettownList");
}
export function getloadselect() {
  return request.get("/api/base/getloadselect");
}
export function fangjiact(data) {
  return request.post("/api/base/savect", data);
}
export function fangjia(data) {
  return request.post("/api/base/save", data);
}
export function fangjiawp(data) {
  return request.post("/api/base/savewp", data);
}
export function fangjiazp(data) {
  return request.post("/api/base/savezp", data);
}
export function fangdai(data) {
  return request.post("/api/load/save", data);
}
export function fangdaiwp(data) {
  return request.post("/api/load/savewp", data);
}
export function sendCode(data) {
  return request.get("/api/base/code", data);
}
export function loadLog(data) {
  return request.get("/api/user/loadlog", data);
}
export function bindsave(data) {
  return request.post("/api/base/bindsave", data);
}
export function getWxUser(data) {
  return request.post("/api/wechat/getWxUser", data);
}
export function getZpList() {
  return request.get("/api/base/getzplist");
}

export function getMenuList() {
  return request.get("/api/menu/list");
}